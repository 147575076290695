.center {
  margin: auto;
  text-align: center;
}
.MuiChip-root{
  background-color: #99a6e5 !important;
}
.MuiListItem-gutters {
  padding-left: 0px !important;
}
.MuiListItem-secondaryAction {
  padding-right: 32px !important;
}
.MuiAutocomplete-option{
  min-height: 32px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.MuiAutocomplete-paper{
  margin: 0 !important;
}
.MuiMenuItem-root{
  padding-left: 16px !important;
}
.MuiListItemSecondaryAction-root{
  top: 28px !important;
}
.MuiAccordionSummary-content.Mui-expanded{
  margin: 0 !important;
}